import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/public/layout';
import Newspanel from '../components/public/newsPanel'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import { Helmet } from 'react-helmet';
export const query = graphql`
    query($slug: String!) {
        contentfulArtykulyStronaGlowna(linkName: {eq: $slug}){
            title
            content {
                raw
                references{
                    contentful_id
                    title
                  	file {
                      url
                    }
                }
            }
        }
    }
`
const Articlemain = (props) => {
    console.log(props)
    const assets = new Map(props.data.contentfulArtykulyStronaGlowna.content.references.map(ref => [ref.contentful_id, ref]))

    const options = {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                const url = assets.get(node.data.target.sys.id).file.url
                const alt = assets.get(node.data.target.sys.id).title
                return <img alt={alt} src={url} style={{ width: '100%' }} />
            }
        }
    }

    // const locationPath = props.path.split('/')
    return (
        <Layout>
            <Helmet title={`${props.data.contentfulArtykulyStronaGlowna.title} | Parafia w Starych Skoszewach`} />
            <div className="container">
                <div className="left-col">
                    <div className="article-without-photo">
                        {
                            props.path.includes('cmentarz') ?
                                <span className='where'>
                                    <Link to='/'>Strona Główna</Link>
                                    <span className='where-arrow'>&gt;</span>
                                    <Link to='/cmentarz'>Cmentarz</Link>
                                </span>
                                : null
                        }
                        <h3>{props.data.contentfulArtykulyStronaGlowna.title}</h3>
                        <div style={{ color: '#111111', textAlign: 'justify' }}>
                            {documentToReactComponents(JSON.parse(props.data.contentfulArtykulyStronaGlowna.content.raw), options)}
                        </div>
                    </div>
                </div>
                <div className="right-col">
                    <Newspanel />
                    {/* <div className="facebook-panel"></div> */}
                </div>
            </div>
        </Layout>
    );
}

export default Articlemain;
